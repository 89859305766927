import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GuaranteeFormUtils } from '../../core/utils/guarantee.utils';
import { Subscription } from 'rxjs';
import { PmaService } from '../../pma/pma.service';
import { GetOrCreateResponse, ResidentialService } from '../../core/services/residential.service';
import { BrandingService } from '../../core/services/branding.service';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ObjectIdValidator } from '../../auth/_helpers/object-id.validator';
import { InsurablesFetchResult, InsurablesService } from '../../core/services/insurables.service';

@Component({
  selector: 'ui-insurables',
  templateUrl: './ui-insurables.component.html',
  styleUrls: ['./ui-insurables.component.scss']
})
export class UiInsurablesComponent implements AfterViewInit {
  @Input() nonpreferredAddressSelection;
  @Input() pmaSingleHouses;
  @Input() form: FormGroup;
  @Input() nonApplicableControl: FormControl;
  @Input() communityControl: FormControl;
  @Input() buildingControl: FormControl;
  @Input() buildingAddressControl: FormControl;
  @Input() buildingAddressCheckbox: FormControl;

  // Use this option to show connected fields progressively in a logic order
  // State, then community, then unit, so user doesn't get confused why some fields have no options
  @Input() progressiveRendering = false;

  @Output() setFullAddress = new EventEmitter();
  @Output() setAccountId = new EventEmitter();
  @Output() setAddressAttributes = new EventEmitter();
  @Output() stopLoading = new EventEmitter();
  @Output() setPreferredho4 = new EventEmitter();
  @Output() startLoading = new EventEmitter();
  preferred_ho4;
  buildings;
  units;
  stateList = GuaranteeFormUtils.STATE_LIST;
  subscription = new Subscription();
  fetchedCommunity;
  loader = false;

  constructor(private fb: FormBuilder,
              private pmaService: PmaService,
              private residentialService: ResidentialService,
              private brandingService: BrandingService,
              private cd: ChangeDetectorRef,
              private toastr: ToastrService,
              private insurablesService: InsurablesService) {
  }

  ngAfterViewInit(): void {
    if (this.pmaSingleHouses) {
      this.onPmaSingleHouses();
    } else {
      this.onStandardFlow();
    }
  }

  onPmaSingleHouses() {
    this.buildingControl.disable({ emitEvent: false });
    this.form.get('unit').disable({ emitEvent: false });
  }

  onStandardFlow() {
    if (!this.nonpreferredAddressSelection) {
      this.onCommunitySelector();
    } else {
      this.onAddressPicker();
    }
    this.onUnitChanges();
  }

  onCommunitySelector() {
    this.getStatesByBrandingId();
    this.getCommunityEntities();
    this.onChangesBuilding();
    this.onChangesCommunity();
    this.onChangesState();
    this.setCommunityValidators();
  }

  onAddressPicker() {
    this.getAddressEntities();
    this.onChangesAddress();
    this.onChangesBuilding();
    this.onChangesNonApplicableControl();
  }

  getCommunityEntities() {
    if (this.buildingControl && this.buildingControl.value) {
      this.getUnitsByBuilding(this.buildingControl.value);
    }
    this.loadBuildingsAndUnit(false);
  }

  getAddressEntities() {
    this.getOrCreateBuildingsByAddress();
  }

  getUnitsByBuilding(building) {
    this.units = building && building.units ? this.setUnits(building.units) : [];
  }

  getStatesByBrandingId() {
    this.pmaService.getStatesByBrandingId(this.brandingService.getBrandingInfoSnapshot().id).subscribe((states: string[]) => {
      this.stateList = states.sort((a, b) => a < b ? -1 : a > b ? 1 : 0);
      this.cd.detectChanges();
    });
  }

  onChangesState() {
    this.subscription.add(
      this.form.get('state').valueChanges.subscribe(state => {
        this.communityControl.reset(null);
      })
    );
  }

  onChangesBuilding() {
    this.subscription.add(
      this.buildingControl.valueChanges.subscribe(building => {
        if (building) {
          this.units = building && building.insurables ? this.setUnits(building.insurables) : [];
          this.form.get('unit').reset(null);
          this.setFullAddressByBuilding(building);
        } else {
          this.units = this.fetchedCommunity && this.fetchedCommunity.units ? this.setUnits(this.fetchedCommunity.units) : [];
        }
        this.toggleUnit();
      })
    );
  }

  setFullAddressByBuilding(building) {
    if (building && building.id) {
      const primaryAddress = building && building.primary_address && building.primary_address.full;
      if (primaryAddress) {
        this.setFullAddress.emit(primaryAddress);
      }
    }
  }

  onChangesAddress() {
    this.subscription.add(
      this.form.get('address').valueChanges.subscribe(address => {
        this.buildingAddressControl.reset();
        this.buildingAddressCheckbox.reset();
        this.buildingControl.reset();
        this.toggleUnit();
      })
    )
  }

  onChangesCommunity() {
    this.subscription.add(
      this.communityControl.valueChanges.subscribe(community => {
        this.buildingControl.reset(null);
        this.form.get('unit').reset(null);
        if (community && community.id) {
          this.setFullAddressByCommunity(community);
          this.loadBuildingsAndUnit();
        }
        this.toggleUnit();
      })
    );
  }

  setFullAddressByCommunity(community) {
    if (community && community.id) {
      const addressAttributes = community && community.addresses_attributes && community.addresses_attributes[0];
      const address = addressAttributes && addressAttributes.full ? addressAttributes.full : '';
      if (address) {
        this.setFullAddress.emit(address);
      }
    }
  }

  private loadBuildingsAndUnit(withUnitToggle = true): void {
    const communityId = this.communityControl.value && this.communityControl.value.id ? this.communityControl.value.id : null;

    if (!communityId) {
      return;
    }

    this.loader = true;
    this.buildings = [];
    this.units = [];

    this.insurablesService.getBuildingsAndUnits(communityId).pipe(
      finalize(() => this.loader = false)
    ).subscribe((result: InsurablesFetchResult) => {
      this.fetchedCommunity = result;
      this.buildings = result.community_with_buildings ? result.insurables : [];

      if (result.community_with_buildings) {
        this.toggleBuilding();
      } else {
        this.units = result.insurables;
      }

      if (this.isBrandingSecondNature && this.units && this.units.length === 0) {
        this.form.get('unit').patchValue({ title: 'N/A' });
      }

      if (withUnitToggle) {
        this.toggleUnit();
      }
    }, () => this.stopLoading.emit(true));
  }

  onUnitChanges() {
    this.subscription.add(
      this.form.get('unit').valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(250),
      ).subscribe(unit => {
        if (unit) {
          this.getOrCreateUnit();
        }
      })
    );
  }

  getOrCreateUnit() {
    this.startLoading.emit();

    const unitId = this.form.get('unit')?.value?.id ?? null;
    if (this.nonpreferredAddressSelection) {
      if (this.buildingAddressCheckbox.value) {
        this.getOrCreateUnitByBuildingId();
      } else {
        this.getOrCreateUnitFromAddress(unitId);
      }
    } else if (unitId) {
      this.getOrCreateUnitById(unitId);
    }
  }

  getOrCreateUnitById(unitId) {
    this.residentialService.getOrCreateInsurableByInsurableId(unitId).pipe(
      finalize(() => this.loader = false)
    ).subscribe((result) => {
      this.form.get('insurable_id').setValue(unitId);
      this.emitAddressAttributes(result);
      this.stopLoading.emit(true)
    }, err => this.stopLoading.emit(true));
  }

  getOrCreateUnitFromAddress(unitId = null) {
    const form = this.form.value;
    this.residentialService.getOrCreateUnit(form)
      .subscribe((result: GetOrCreateResponse) => {
        const insurableId = unitId ? unitId : result.results[0].id;
        this.form.get('insurable_id').setValue(insurableId);
        this.emitAddressAttributes(result);
        this.stopLoading.emit(true);
      }, err => this.stopLoading.emit(true));
  }

  getOrCreateUnitByBuildingId(unitId = null) {
    const form = this.form.value;
    const titleless = !form.unit;
    const buildingId = this.buildingControl && this.buildingControl.value && this.buildingControl.value.id ?
      this.buildingControl.value.id : null;
    if (buildingId) {
      this.residentialService.getOrCreateUnitByBuildingId(form.address, form.unit, titleless, buildingId)
        .subscribe((result: GetOrCreateResponse) => {
          const insurableId = unitId ? unitId : result.results[0].id;
          this.form.get('insurable_id').setValue(insurableId);
          this.emitAddressAttributes(result);
          this.stopLoading.emit(true)
        }, err => this.stopLoading.emit(true));
    } else {
      this.toastr.error('Invalid Building');
    }
  }

  emitAddressAttributes(res) {
    const addressAttributes = res && res.results && res.results[0] && res.results[0].primary_address ? res.results[0].primary_address : null;
    if (addressAttributes) {
      this.setAddressAttributes.emit(addressAttributes);
    } else {
      this.toastr.error('Invalid unit selected');
    }
  }

  setUnits(units) {
    return this.sortInsurablesByTitle(this.setEmptyUnitsToNA(units));
  }

  setEmptyUnitsToNA(units) {
    return units.map(unit => (
        {
          ...unit,
          title: unit?.title ? unit?.title : 'N/A'
        }
      )
    );
  }

  onChangesNonApplicableControl() {
    this.nonApplicableControl.valueChanges.subscribe(checkbox => {
      this.toggleUnit();
      this.getOrCreateUnit();
    })
  }

  toggleUnit() {
    if (!this.isNonApplicableValue && (this.isCommunityAndUnits || this.isAddressValue)) {
      this.setUnitValidators();
      this.form.get('unit').enable();
      this.form.get('unit').updateValueAndValidity({ emitEvent: false });
    } else {
      this.form.get('unit').clearValidators();
      this.form.get('unit').disable();
      this.form.get('unit').reset('', { emitEvent: false });
    }
  }

  setUnitValidators() {
    if (this.nonpreferredAddressSelection) {
      this.form.get('unit').setValidators([Validators.required]);
    } else {
      this.form.get('unit').setValidators([Validators.required, ObjectIdValidator]);
    }
  }

  setCommunityValidators() {
    this.communityControl.setValidators([Validators.required, ObjectIdValidator]);
  }

  toggleBuilding() {
    if (this.buildings && this.buildings.length > 0) {
      this.buildingControl.setValidators([Validators.required, ObjectIdValidator]);
      this.buildingControl.enable({ emitEvent: false });
      this.buildingControl.updateValueAndValidity({ emitEvent: false });
    } else {
      this.buildingControl.clearValidators();
      this.buildingControl.disable();
      this.buildingControl.reset('', { emitEvent: false });
    }
  }

  getOrCreateBuildingsByAddress(): void {
    const form = this.form.value;
    if (form && form.address && form.county && form.neighborhood) {
      this.residentialService.getOrCreateInsurable(form)
        .subscribe((result: GetOrCreateResponse) =>  {
          const community = result && result.results && result.results[0] ? result.results[0] : null;
          const buildings = community && community.buildings ? community.buildings : [];
          this.setBuildingsFromAddress(buildings);
          const units = community && community.units ? community.units : [];
          this.setUnitsFromAddress(units);
        });
    }
  }

  setBuildingsFromAddress(buildings) {
    this.buildings = buildings;
    this.buildingControl.reset();
  }

  setUnitsFromAddress(units) {
    this.units = units;
    this.form.get('unit').reset();
  }

  emitAccountId(accountId) {
    this.setAccountId.emit(accountId);
  }

  setCommunity(community) {
    this.fetchedCommunity = community;
    this.preferred_ho4 = this.fetchedCommunity.preferred_ho4;
    this.setPreferredho4.emit(this.preferred_ho4);
  }

  setBuilding(building) {
    this.buildingControl.patchValue(building);
  }

  private sortInsurablesByTitle(insurables) {
    return insurables.sort((a, b) => a?.title < b?.title ? -1 : a?.title > b?.title ? 1 : 0);
  }

  get isNonApplicableValue() {
    return this.nonApplicableControl.value;
  }

  get isCommunityAndUnits() {
    return this.isCommunity && this.units && this.units.length > 0;
  }

  get isAddressValue() {
    return this.form.get('address') && this.form.get('address').value;
  }

  get isCommunity() {
    return this.communityControl && this.communityControl.value && this.communityControl.value.id;
  }

  get f() {
    return this.form.controls;
  }

  get isBuildingControl() {
    return this.isBuildingValue || (this.buildings && this.buildings.length > 0);
  }

  get isBuildingCheckbox() {
    return this.isAddressValue && this.preferred_ho4 === false;
  }

  get isBuildingAddress() {
    return this.buildingAddressCheckbox && this.buildingAddressCheckbox.value && this.preferred_ho4 === false;
  }

  get isBuildingValue() {
    return this.buildingControl && this.buildingControl.value;
  }

  get isBrandingSecondNature() {
    const slug = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes.find(item => item.name === 'branding_profile_slug')?.value;
    return slug === 'second_nature' || slug === 'pinata';
  }

  get brandingId() {
    return this.brandingService.getBrandingInfoSnapshot().id;
  }
}

